[data-color-mode="dark"][data-dark-theme="dark_dimmed"],
[data-color-mode="dark"][data-dark-theme="dark_dimmed"] ::backdrop,
[data-color-mode="auto"][data-light-theme="dark_dimmed"],
[data-color-mode="auto"][data-light-theme="dark_dimmed"] ::backdrop {
  --topicTag-borderColor: #00000000;
  --highlight-neutral-bgColor: #c6902666;
  --page-header-bgColor: #22272e;
  --diffBlob-addition-fgColor-text: #c5d1de;
  --diffBlob-addition-fgColor-num: #c5d1de;
  --diffBlob-addition-bgColor-num: #57ab5a4d;
  --diffBlob-addition-bgColor-line: #46954a26;
  --diffBlob-addition-bgColor-word: #46954a66;
  --diffBlob-deletion-fgColor-text: #c5d1de;
  --diffBlob-deletion-fgColor-num: #c5d1de;
  --diffBlob-deletion-bgColor-num: #e5534b4d;
  --diffBlob-deletion-bgColor-line: #e5534b26;
  --diffBlob-deletion-bgColor-word: #e5534b66;
  --diffBlob-hunk-bgColor-num: #4184e466;
  --diffBlob-expander-iconColor: #c5d1de;
  --codeMirror-fgColor: #c5d1de;
  --codeMirror-bgColor: #22272e;
  --codeMirror-gutters-bgColor: #22272e;
  --codeMirror-gutterMarker-fgColor-default: #22272e;
  --codeMirror-gutterMarker-fgColor-muted: #778491;
  --codeMirror-lineNumber-fgColor: #778491;
  --codeMirror-cursor-fgColor: #c5d1de;
  --codeMirror-selection-bgColor: #4184e466;
  --codeMirror-activeline-bgColor: #636e7b66;
  --codeMirror-matchingBracket-fgColor: #c5d1de;
  --codeMirror-lines-bgColor: #22272e;
  --codeMirror-syntax-fgColor-comment: #768390;
  --codeMirror-syntax-fgColor-constant: #6cb6ff;
  --codeMirror-syntax-fgColor-entity: #dcbdfb;
  --codeMirror-syntax-fgColor-keyword: #f47067;
  --codeMirror-syntax-fgColor-storage: #f47067;
  --codeMirror-syntax-fgColor-string: #96d0ff;
  --codeMirror-syntax-fgColor-support: #6cb6ff;
  --codeMirror-syntax-fgColor-variable: #f69d50;
  --header-fgColor-default: #cdd9e5b3;
  --header-fgColor-logo: #cdd9e5;
  --header-bgColor: #2d333bf2;
  --header-borderColor-divider: #768390;
  --headerSearch-bgColor: #22272e;
  --headerSearch-borderColor: #444c56;
  --data-blue-color: #0576ff;
  --data-blue-color-muted: #001a47;
  --data-auburn-color: #a86f6b;
  --data-auburn-color-muted: #271817;
  --data-orange-color: #984b10;
  --data-orange-color-muted: #311708;
  --data-yellow-color: #895906;
  --data-yellow-color-muted: #2e1a00;
  --data-green-color: #2f6f37;
  --data-green-color-muted: #122117;
  --data-teal-color: #106c70;
  --data-teal-color-muted: #041f25;
  --data-purple-color: #975bf1;
  --data-purple-color-muted: #211047;
  --data-pink-color: #d34591;
  --data-pink-color-muted: #2d1524;
  --data-red-color: #eb3342;
  --data-red-color-muted: #3c0614;
  --data-gray-color: #576270;
  --data-gray-color-muted: #1c1c1c;
  --display-blue-bgColor-muted: #001a47;
  --display-blue-bgColor-emphasis: #0576ff;
  --display-blue-fgColor: #4da0ff;
  --display-blue-borderColor-muted: #002766;
  --display-blue-borderColor-emphasis: #0576ff;
  --display-green-bgColor-muted: #122117;
  --display-green-bgColor-emphasis: #388f3f;
  --display-green-fgColor: #41b445;
  --display-green-borderColor-muted: #182f1f;
  --display-green-borderColor-emphasis: #388f3f;
  --display-orange-bgColor-muted: #311708;
  --display-orange-bgColor-emphasis: #c46212;
  --display-orange-fgColor: #ed8326;
  --display-orange-borderColor-muted: #43200a;
  --display-orange-borderColor-emphasis: #c46212;
  --display-purple-bgColor-muted: #211047;
  --display-purple-bgColor-emphasis: #975bf1;
  --display-purple-fgColor: #b687f7;
  --display-purple-borderColor-muted: #31146b;
  --display-purple-borderColor-emphasis: #975bf1;
  --display-plum-bgColor-muted: #2a0e3f;
  --display-plum-bgColor-emphasis: #b643ef;
  --display-plum-fgColor: #d07ef7;
  --display-plum-borderColor-muted: #40125e;
  --display-plum-borderColor-emphasis: #b643ef;
  --display-red-bgColor-muted: #3c0614;
  --display-red-bgColor-emphasis: #eb3342;
  --display-red-fgColor: #f27d83;
  --display-red-borderColor-muted: #58091a;
  --display-red-borderColor-emphasis: #eb3342;
  --display-coral-bgColor-muted: #3c0614;
  --display-coral-bgColor-emphasis: #eb3342;
  --display-coral-fgColor: #f27d83;
  --display-coral-borderColor-muted: #58091a;
  --display-coral-borderColor-emphasis: #eb3342;
  --display-yellow-bgColor-muted: #2e1a00;
  --display-yellow-bgColor-emphasis: #aa7109;
  --display-yellow-fgColor: #d3910d;
  --display-yellow-borderColor-muted: #3d2401;
  --display-yellow-borderColor-emphasis: #aa7109;
  --display-gray-bgColor-muted: #1c1c1c;
  --display-gray-bgColor-emphasis: #6e7f96;
  --display-gray-fgColor: #92a1b5;
  --display-gray-borderColor-muted: #2a2b2d;
  --display-gray-borderColor-emphasis: #6e7f96;
  --display-auburn-bgColor-muted: #271817;
  --display-auburn-bgColor-emphasis: #a86f6b;
  --display-auburn-fgColor: #bf9592;
  --display-auburn-borderColor-muted: #3a2422;
  --display-auburn-borderColor-emphasis: #a86f6b;
  --display-brown-bgColor-muted: #241c14;
  --display-brown-bgColor-emphasis: #94774c;
  --display-brown-fgColor: #b69a6d;
  --display-brown-borderColor-muted: #342a1d;
  --display-brown-borderColor-emphasis: #94774c;
  --display-lemon-bgColor-muted: #291d00;
  --display-lemon-bgColor-emphasis: #977b0c;
  --display-lemon-fgColor: #ba9b12;
  --display-lemon-borderColor-muted: #372901;
  --display-lemon-borderColor-emphasis: #977b0c;
  --display-olive-bgColor-muted: #171e0b;
  --display-olive-bgColor-emphasis: #7a8321;
  --display-olive-fgColor: #a2a626;
  --display-olive-borderColor-muted: #252d10;
  --display-olive-borderColor-emphasis: #7a8321;
  --display-lime-bgColor-muted: #141f0f;
  --display-lime-bgColor-emphasis: #5f892f;
  --display-lime-fgColor: #7dae37;
  --display-lime-borderColor-muted: #1f3116;
  --display-lime-borderColor-emphasis: #5f892f;
  --display-pine-bgColor-muted: #082119;
  --display-pine-bgColor-emphasis: #18915e;
  --display-pine-fgColor: #1bb673;
  --display-pine-borderColor-muted: #0b3224;
  --display-pine-borderColor-emphasis: #18915e;
  --display-teal-bgColor-muted: #041f25;
  --display-teal-bgColor-emphasis: #158a8a;
  --display-teal-fgColor: #1cb0ab;
  --display-teal-borderColor-muted: #073036;
  --display-teal-borderColor-emphasis: #158a8a;
  --display-cyan-bgColor-muted: #001f29;
  --display-cyan-bgColor-emphasis: #0587b3;
  --display-cyan-fgColor: #07ace4;
  --display-cyan-borderColor-muted: #002e3d;
  --display-cyan-borderColor-emphasis: #0587b3;
  --display-indigo-bgColor-muted: #1b183f;
  --display-indigo-bgColor-emphasis: #7070e1;
  --display-indigo-fgColor: #9899ec;
  --display-indigo-borderColor-muted: #25215f;
  --display-indigo-borderColor-emphasis: #7070e1;
  --display-pink-bgColor-muted: #2d1524;
  --display-pink-bgColor-emphasis: #d34591;
  --display-pink-fgColor: #e57bb2;
  --display-pink-borderColor-muted: #451c35;
  --display-pink-borderColor-emphasis: #d34591;
  --avatar-bgColor: #cdd9e51a;
  --avatar-borderColor: #cdd9e526;
  --avatar-shadow: 0px 0px 0px 2px #22272e;
  --avatarStack-fade-bgColor-default: #444c56;
  --avatarStack-fade-bgColor-muted: #373e47;
  --control-bgColor-rest: #373e47;
  --control-bgColor-hover: #3d444e;
  --control-bgColor-active: #434a54;
  --control-bgColor-disabled: #373e47b3;
  --control-bgColor-selected: #2d333b;
  --control-fgColor-rest: #adbac7;
  --control-fgColor-placeholder: #727f8c;
  --control-fgColor-disabled: #636e7b;
  --control-borderColor-rest: #444c56;
  --control-borderColor-emphasis: #606b77;
  --control-borderColor-disabled: #373e47b3;
  --control-borderColor-selected: #cdd9e5;
  --control-borderColor-success: #347d39;
  --control-borderColor-danger: #c93c37;
  --control-borderColor-warning: #966600;
  --control-iconColor-rest: #778491;
  --control-transparent-bgColor-rest: #00000000;
  --control-transparent-bgColor-hover: #909dab1f;
  --control-transparent-bgColor-active: #909dab33;
  --control-transparent-bgColor-disabled: #373e47b3;
  --control-transparent-bgColor-selected: #909dab14;
  --control-transparent-borderColor-rest: #00000000;
  --control-transparent-borderColor-hover: #00000000;
  --control-transparent-borderColor-active: #00000000;
  --control-danger-fgColor-rest: #e5534b;
  --control-danger-fgColor-hover: #f47067;
  --control-danger-bgColor-hover: #e5534b1a;
  --control-danger-bgColor-active: #e5534b66;
  --control-checked-bgColor-rest: #316dca;
  --control-checked-bgColor-hover: #3876d3;
  --control-checked-bgColor-active: #3f7fdb;
  --control-checked-bgColor-disabled: #636e7b;
  --control-checked-fgColor-rest: #cdd9e5;
  --control-checked-fgColor-disabled: #1c2128;
  --control-checked-borderColor-rest: #316dca;
  --control-checked-borderColor-hover: #3876d3;
  --control-checked-borderColor-active: #3f7fdb;
  --control-checked-borderColor-disabled: #636e7b;
  --controlTrack-bgColor-rest: #373e47;
  --controlTrack-bgColor-hover: #3d444e;
  --controlTrack-bgColor-active: #434a54;
  --controlTrack-bgColor-disabled: #636e7b;
  --controlTrack-fgColor-rest: #778491;
  --controlTrack-fgColor-disabled: #cdd9e5;
  --controlTrack-borderColor-rest: #00000000;
  --controlTrack-borderColor-disabled: #636e7b;
  --controlKnob-bgColor-rest: #1c2128;
  --controlKnob-bgColor-disabled: #373e47b3;
  --controlKnob-bgColor-checked: #cdd9e5;
  --controlKnob-borderColor-rest: #606b77;
  --controlKnob-borderColor-disabled: #373e47b3;
  --controlKnob-borderColor-checked: #316dca;
  --counter-borderColor: #00000000;
  --button-default-fgColor-rest: #adbac7;
  --button-default-bgColor-rest: #373e47;
  --button-default-bgColor-hover: #3d444e;
  --button-default-bgColor-active: #434a54;
  --button-default-bgColor-selected: #434a54;
  --button-default-bgColor-disabled: #373e47b3;
  --button-default-borderColor-rest: #444c56;
  --button-default-borderColor-hover: #444c56;
  --button-default-borderColor-active: #444c56;
  --button-default-borderColor-disabled: #373e47b3;
  --button-default-shadow-resting: 0px 0px 0px 0px #000000;
  --button-primary-fgColor-rest: #ffffff;
  --button-primary-fgColor-disabled: #cdd9e566;
  --button-primary-iconColor-rest: #ffffff;
  --button-primary-bgColor-rest: #347d39;
  --button-primary-bgColor-hover: #3b8640;
  --button-primary-bgColor-active: #428f46;
  --button-primary-bgColor-disabled: #50a254;
  --button-primary-borderColor-rest: #cdd9e51a;
  --button-primary-borderColor-hover: #cdd9e51a;
  --button-primary-borderColor-active: #cdd9e51a;
  --button-primary-borderColor-disabled: #50a254;
  --button-primary-shadow-selected: 0px 0px 0px 0px #000000;
  --button-invisible-fgColor-rest: #478be6;
  --button-invisible-fgColor-hover: #539bf5;
  --button-invisible-fgColor-disabled: #636e7b;
  --button-invisible-iconColor-rest: #778491;
  --button-invisible-iconColor-hover: #778491;
  --button-invisible-iconColor-disabled: #636e7b;
  --button-invisible-bgColor-rest: #00000000;
  --button-invisible-bgColor-hover: #909dab1f;
  --button-invisible-bgColor-active: #909dab33;
  --button-invisible-bgColor-disabled: #373e47b3;
  --button-invisible-borderColor-rest: #00000000;
  --button-invisible-borderColor-hover: #00000000;
  --button-invisible-borderColor-disabled: #373e47b3;
  --button-outline-fgColor-rest: #4184e4;
  --button-outline-fgColor-hover: #539bf5;
  --button-outline-fgColor-active: #cdd9e5;
  --button-outline-fgColor-disabled: #478be680;
  --button-outline-bgColor-rest: #cdd9e5;
  --button-outline-bgColor-hover: #444c56;
  --button-outline-bgColor-active: #1b4b91;
  --button-outline-bgColor-disabled: #22272e;
  --button-outline-borderColor-hover: #cdd9e51a;
  --button-outline-borderColor-selected: #cdd9e51a;
  --button-outline-shadow-selected: 0px 0px 0px 0px #000000;
  --button-danger-fgColor-rest: #e5534b;
  --button-danger-fgColor-hover: #cdd9e5;
  --button-danger-fgColor-active: #cdd9e5;
  --button-danger-fgColor-disabled: #e5534b80;
  --button-danger-iconColor-rest: #e5534b;
  --button-danger-iconColor-hover: #cdd9e5;
  --button-danger-bgColor-rest: #373e47;
  --button-danger-bgColor-hover: #ad2e2c;
  --button-danger-bgColor-active: #c33d38;
  --button-danger-bgColor-disabled: #373e47b3;
  --button-danger-borderColor-rest: #444c56;
  --button-danger-borderColor-hover: #cdd9e51a;
  --button-danger-borderColor-active: #cdd9e51a;
  --button-danger-shadow-selected: 0px 0px 0px 0px #000000;
  --button-inactive-fgColor: #768390;
  --button-inactive-bgColor: #373e47;
  --button-star-iconColor: #daaa3f;
  --buttonCounter-default-bgColor-rest: #444c56;
  --buttonCounter-invisible-bgColor-rest: #444c56;
  --buttonCounter-primary-bgColor-rest: #11341733;
  --buttonCounter-outline-bgColor-rest: #0f2d5c33;
  --buttonCounter-outline-bgColor-hover: #0f2d5c33;
  --buttonCounter-outline-bgColor-disabled: #316dca0d;
  --buttonCounter-outline-fgColor-rest: #4184e4;
  --buttonCounter-outline-fgColor-hover: #539bf5;
  --buttonCounter-outline-fgColor-disabled: #478be680;
  --buttonCounter-danger-bgColor-hover: #cdd9e533;
  --buttonCounter-danger-bgColor-disabled: #c93c370d;
  --buttonCounter-danger-bgColor-rest: #5d0f1233;
  --buttonCounter-danger-fgColor-rest: #e5534b;
  --buttonCounter-danger-fgColor-hover: #cdd9e5;
  --buttonCounter-danger-fgColor-disabled: #e5534b80;
  --reactionButton-selected-bgColor-rest: #4184e433;
  --reactionButton-selected-bgColor-hover: #4285e55c;
  --reactionButton-selected-fgColor-rest: #478be6;
  --reactionButton-selected-fgColor-hover: #6cb6ff;
  --focus-outlineColor: #316dca;
  --menu-bgColor-active: #2d333b;
  --overlay-bgColor: #2d333b;
  --overlay-borderColor: #444c56b3;
  --overlay-backdrop-bgColor: #2d333b66;
  --selectMenu-borderColor: #545d68;
  --selectMenu-bgColor-active: #143d79;
  --sideNav-bgColor-selected: #373e47;
  --skeletonLoader-bgColor: #2d333b;
  --timelineBadge-bgColor: #373e47;
  --treeViewItem-leadingVisual-iconColor-rest: #778491;
  --underlineNav-borderColor-active: #ec775c;
  --underlineNav-borderColor-hover: #636e7b66;
  --underlineNav-iconColor-rest: #778491;
  --selection-bgColor: #316dcab3;
  --card-bgColor: #2d333b;
  --label-green-bgColor-rest: #122117;
  --label-green-bgColor-hover: #182f1f;
  --label-green-bgColor-active: #214529;
  --label-green-fgColor-rest: #41b445;
  --label-green-fgColor-hover: #46c144;
  --label-green-fgColor-active: #75d36f;
  --label-orange-bgColor-rest: #311708;
  --label-orange-bgColor-hover: #43200a;
  --label-orange-bgColor-active: #632f0d;
  --label-orange-fgColor-rest: #ed8326;
  --label-orange-fgColor-hover: #f1933b;
  --label-orange-fgColor-active: #f6b06a;
  --label-purple-bgColor-rest: #211047;
  --label-purple-bgColor-hover: #31146b;
  --label-purple-bgColor-active: #481a9e;
  --label-purple-fgColor-rest: #b687f7;
  --label-purple-fgColor-hover: #c398fb;
  --label-purple-fgColor-active: #d2affd;
  --label-red-bgColor-rest: #3c0614;
  --label-red-bgColor-hover: #58091a;
  --label-red-bgColor-active: #790c20;
  --label-red-fgColor-rest: #f27d83;
  --label-red-fgColor-hover: #f48b8d;
  --label-red-fgColor-active: #f7adab;
  --label-yellow-bgColor-rest: #2e1a00;
  --label-yellow-bgColor-hover: #3d2401;
  --label-yellow-bgColor-active: #5a3702;
  --label-yellow-fgColor-rest: #d3910d;
  --label-yellow-fgColor-hover: #df9e11;
  --label-yellow-fgColor-active: #edb431;
  --label-gray-bgColor-rest: #1c1c1c;
  --label-gray-bgColor-hover: #2a2b2d;
  --label-gray-bgColor-active: #393d41;
  --label-gray-fgColor-rest: #92a1b5;
  --label-gray-fgColor-hover: #9babbf;
  --label-gray-fgColor-active: #b3c0d1;
  --label-auburn-bgColor-rest: #271817;
  --label-auburn-bgColor-hover: #3a2422;
  --label-auburn-bgColor-active: #543331;
  --label-auburn-fgColor-rest: #bf9592;
  --label-auburn-fgColor-hover: #c6a19f;
  --label-auburn-fgColor-active: #d4b7b5;
  --label-brown-bgColor-rest: #241c14;
  --label-brown-bgColor-hover: #342a1d;
  --label-brown-bgColor-active: #483a28;
  --label-brown-fgColor-rest: #b69a6d;
  --label-brown-fgColor-hover: #bfa77d;
  --label-brown-fgColor-active: #cdbb98;
  --label-lemon-bgColor-rest: #291d00;
  --label-lemon-bgColor-hover: #372901;
  --label-lemon-bgColor-active: #4f3c02;
  --label-lemon-fgColor-rest: #ba9b12;
  --label-lemon-fgColor-hover: #c4a717;
  --label-lemon-fgColor-active: #d7bc1d;
  --label-olive-bgColor-rest: #171e0b;
  --label-olive-bgColor-hover: #252d10;
  --label-olive-bgColor-active: #374115;
  --label-olive-fgColor-rest: #a2a626;
  --label-olive-fgColor-hover: #b2af24;
  --label-olive-fgColor-active: #cbc025;
  --label-lime-bgColor-rest: #141f0f;
  --label-lime-bgColor-hover: #1f3116;
  --label-lime-bgColor-active: #2c441d;
  --label-lime-fgColor-rest: #7dae37;
  --label-lime-fgColor-hover: #89ba36;
  --label-lime-fgColor-active: #9fcc3e;
  --label-pine-bgColor-rest: #082119;
  --label-pine-bgColor-hover: #0b3224;
  --label-pine-bgColor-active: #0e4430;
  --label-pine-fgColor-rest: #1bb673;
  --label-pine-fgColor-hover: #1ac176;
  --label-pine-fgColor-active: #1bda81;
  --label-teal-bgColor-rest: #041f25;
  --label-teal-bgColor-hover: #073036;
  --label-teal-bgColor-active: #0a464d;
  --label-teal-fgColor-rest: #1cb0ab;
  --label-teal-fgColor-hover: #1fbdb2;
  --label-teal-fgColor-active: #24d6c4;
  --label-cyan-bgColor-rest: #001f29;
  --label-cyan-bgColor-hover: #002e3d;
  --label-cyan-bgColor-active: #014156;
  --label-cyan-fgColor-rest: #07ace4;
  --label-cyan-fgColor-hover: #09b7f1;
  --label-cyan-fgColor-active: #45cbf7;
  --label-indigo-bgColor-rest: #1b183f;
  --label-indigo-bgColor-hover: #25215f;
  --label-indigo-bgColor-active: #312c90;
  --label-indigo-fgColor-rest: #9899ec;
  --label-indigo-fgColor-hover: #a2a5f1;
  --label-indigo-fgColor-active: #b7baf6;
  --label-blue-bgColor-rest: #001a47;
  --label-blue-bgColor-hover: #002766;
  --label-blue-bgColor-active: #00378a;
  --label-blue-fgColor-rest: #4da0ff;
  --label-blue-fgColor-hover: #61adff;
  --label-blue-fgColor-active: #85c2ff;
  --label-plum-bgColor-rest: #2a0e3f;
  --label-plum-bgColor-hover: #40125e;
  --label-plum-bgColor-active: #5c1688;
  --label-plum-fgColor-rest: #d07ef7;
  --label-plum-fgColor-hover: #d889fa;
  --label-plum-fgColor-active: #e4a5fd;
  --label-pink-bgColor-rest: #2d1524;
  --label-pink-bgColor-hover: #451c35;
  --label-pink-bgColor-active: #65244a;
  --label-pink-fgColor-rest: #e57bb2;
  --label-pink-fgColor-hover: #ec8dbd;
  --label-pink-fgColor-active: #f4a9cd;
  --label-coral-bgColor-rest: #351008;
  --label-coral-bgColor-hover: #51180b;
  --label-coral-bgColor-active: #72220d;
  --label-coral-fgColor-rest: #f7794b;
  --label-coral-fgColor-hover: #fa8c61;
  --label-coral-fgColor-active: #fdaa86;
  --fgColor-default: #c5d1de;
  --fgColor-muted: #778491;
  --fgColor-onEmphasis: #cdd9e5;
  --fgColor-white: #cdd9e5;
  --fgColor-black: #1c2128;
  --fgColor-disabled: #636e7b;
  --fgColor-link: #478be6;
  --fgColor-neutral: #636e7b;
  --fgColor-accent: #478be6;
  --fgColor-success: #57ab5a;
  --fgColor-attention: #c69026;
  --fgColor-severe: #cc6b2c;
  --fgColor-danger: #e5534b;
  --fgColor-open: #57ab5a;
  --fgColor-closed: #e5534b;
  --fgColor-done: #986ee2;
  --fgColor-upsell: #986ee2;
  --fgColor-sponsors: #c96198;
  --bgColor-default: #22272e;
  --bgColor-muted: #2d333b;
  --bgColor-inset: #1c2128;
  --bgColor-emphasis: #636e7b;
  --bgColor-inverse: #cdd9e5;
  --bgColor-white: #cdd9e5;
  --bgColor-black: #1c2128;
  --bgColor-disabled: #373e47b3;
  --bgColor-transparent: #00000000;
  --bgColor-neutral-muted: #636e7b66;
  --bgColor-neutral-emphasis: #636e7b;
  --bgColor-accent-muted: #4184e41a;
  --bgColor-accent-emphasis: #316dca;
  --bgColor-success-muted: #46954a26;
  --bgColor-success-emphasis: #347d39;
  --bgColor-attention-muted: #ae7c1426;
  --bgColor-attention-emphasis: #966600;
  --bgColor-severe-muted: #cc6b2c1a;
  --bgColor-severe-emphasis: #ae5622;
  --bgColor-danger-muted: #e5534b1a;
  --bgColor-danger-emphasis: #c93c37;
  --bgColor-open-muted: #46954a1a;
  --bgColor-open-emphasis: #347d39;
  --bgColor-closed-muted: #e5534b26;
  --bgColor-closed-emphasis: #c93c37;
  --bgColor-done-muted: #986ee226;
  --bgColor-done-emphasis: #8256d0;
  --bgColor-upsell-muted: #986ee226;
  --bgColor-upsell-emphasis: #8256d0;
  --bgColor-sponsors-muted: #c961981a;
  --bgColor-sponsors-emphasis: #ae4c82;
  --borderColor-default: #444c56;
  --borderColor-muted: #444c56b3;
  --borderColor-emphasis: #545d68;
  --borderColor-disabled: #373e47b3;
  --borderColor-transparent: #00000000;
  --borderColor-translucent: #cdd9e526;
  --borderColor-neutral-muted: #636e7b66;
  --borderColor-neutral-emphasis: #636e7b;
  --borderColor-accent-muted: #4184e466;
  --borderColor-accent-emphasis: #316dca;
  --borderColor-success-muted: #46954a66;
  --borderColor-success-emphasis: #347d39;
  --borderColor-attention-muted: #ae7c1466;
  --borderColor-attention-emphasis: #966600;
  --borderColor-severe-muted: #cc6b2c66;
  --borderColor-severe-emphasis: #ae5622;
  --borderColor-danger-muted: #e5534b66;
  --borderColor-danger-emphasis: #c93c37;
  --borderColor-open-muted: #46954a66;
  --borderColor-open-emphasis: #347d39;
  --borderColor-closed-muted: #e5534b66;
  --borderColor-closed-emphasis: #c93c37;
  --borderColor-done-muted: #986ee266;
  --borderColor-done-emphasis: #8256d0;
  --borderColor-upsell-muted: #986ee266;
  --borderColor-upsell-emphasis: #8256d0;
  --borderColor-sponsors-muted: #c9619866;
  --borderColor-sponsors-emphasis: #ae4c82;
  --color-ansi-black: #545d68;
  --color-ansi-black-bright: #636e7b;
  --color-ansi-white: #909dab;
  --color-ansi-white-bright: #cdd9e5;
  --color-ansi-gray: #636e7b;
  --color-ansi-red: #f47067;
  --color-ansi-red-bright: #ff938a;
  --color-ansi-green: #57ab5a;
  --color-ansi-green-bright: #6bc46d;
  --color-ansi-yellow: #c69026;
  --color-ansi-yellow-bright: #daaa3f;
  --color-ansi-blue: #539bf5;
  --color-ansi-blue-bright: #6cb6ff;
  --color-ansi-magenta: #b083f0;
  --color-ansi-magenta-bright: #dcbdfb;
  --color-ansi-cyan: #39c5cf;
  --color-ansi-cyan-bright: #56d4dd;
  --color-prettylights-syntax-comment: #768390;
  --color-prettylights-syntax-constant: #6cb6ff;
  --color-prettylights-syntax-constant-other-reference-link: #96d0ff;
  --color-prettylights-syntax-entity: #dcbdfb;
  --color-prettylights-syntax-storage-modifier-import: #adbac7;
  --color-prettylights-syntax-entity-tag: #8ddb8c;
  --color-prettylights-syntax-keyword: #f47067;
  --color-prettylights-syntax-string: #96d0ff;
  --color-prettylights-syntax-variable: #f69d50;
  --color-prettylights-syntax-brackethighlighter-unmatched: #e5534b;
  --color-prettylights-syntax-brackethighlighter-angle: #768390;
  --color-prettylights-syntax-invalid-illegal-text: #cdd9e5;
  --color-prettylights-syntax-invalid-illegal-bg: #922323;
  --color-prettylights-syntax-carriage-return-text: #cdd9e5;
  --color-prettylights-syntax-carriage-return-bg: #ad2e2c;
  --color-prettylights-syntax-string-regexp: #8ddb8c;
  --color-prettylights-syntax-markup-list: #eac55f;
  --color-prettylights-syntax-markup-heading: #316dca;
  --color-prettylights-syntax-markup-italic: #adbac7;
  --color-prettylights-syntax-markup-bold: #adbac7;
  --color-prettylights-syntax-markup-deleted-text: #ffd8d3;
  --color-prettylights-syntax-markup-deleted-bg: #78191b;
  --color-prettylights-syntax-markup-inserted-text: #b4f1b4;
  --color-prettylights-syntax-markup-inserted-bg: #1b4721;
  --color-prettylights-syntax-markup-changed-text: #ffddb0;
  --color-prettylights-syntax-markup-changed-bg: #682d0f;
  --color-prettylights-syntax-markup-ignored-text: #adbac7;
  --color-prettylights-syntax-markup-ignored-bg: #255ab2;
  --color-prettylights-syntax-meta-diff-range: #dcbdfb;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #545d68;
  --shadow-inset: inset 0px 1px 0px 0px #1c21283d;
  --shadow-resting-xsmall: 0px 1px 0px 0px #1c2128cc;
  --shadow-resting-small: 0px 1px 0px 0px #1c212866;
  --shadow-resting-medium: 0px 3px 6px 0px #1c2128cc;
  --shadow-floating-small: 0px 0px 0px 1px #444c56, 0px 6px 12px -3px #1c212866, 0px 6px 18px 0px #1c212866;
  --shadow-floating-medium: 0px 0px 0px 1px #444c56, 0px 8px 16px -4px #1c212866, 0px 4px 32px -4px #1c212866, 0px 24px 48px -12px #1c212866, 0px 48px 96px -24px #1c212866;
  --shadow-floating-large: 0px 0px 0px 1px #444c56, 0px 24px 48px 0px #1c2128;
  --shadow-floating-xlarge: 0px 0px 0px 1px #444c56, 0px 32px 64px 0px #1c2128;
  --shadow-floating-legacy: 0px 6px 12px -3px #1c212866, 0px 6px 18px 0px #1c212866;
  --outline-focus: #316dca solid 2px;
}
@media (prefers-color-scheme: dark) {
  [data-color-mode="auto"][data-dark-theme="dark_dimmed"],
  [data-color-mode="auto"][data-dark-theme="dark_dimmed"] ::backdrop {
    --topicTag-borderColor: #00000000;
    --highlight-neutral-bgColor: #c6902666;
    --page-header-bgColor: #22272e;
    --diffBlob-addition-fgColor-text: #c5d1de;
    --diffBlob-addition-fgColor-num: #c5d1de;
    --diffBlob-addition-bgColor-num: #57ab5a4d;
    --diffBlob-addition-bgColor-line: #46954a26;
    --diffBlob-addition-bgColor-word: #46954a66;
    --diffBlob-deletion-fgColor-text: #c5d1de;
    --diffBlob-deletion-fgColor-num: #c5d1de;
    --diffBlob-deletion-bgColor-num: #e5534b4d;
    --diffBlob-deletion-bgColor-line: #e5534b26;
    --diffBlob-deletion-bgColor-word: #e5534b66;
    --diffBlob-hunk-bgColor-num: #4184e466;
    --diffBlob-expander-iconColor: #c5d1de;
    --codeMirror-fgColor: #c5d1de;
    --codeMirror-bgColor: #22272e;
    --codeMirror-gutters-bgColor: #22272e;
    --codeMirror-gutterMarker-fgColor-default: #22272e;
    --codeMirror-gutterMarker-fgColor-muted: #778491;
    --codeMirror-lineNumber-fgColor: #778491;
    --codeMirror-cursor-fgColor: #c5d1de;
    --codeMirror-selection-bgColor: #4184e466;
    --codeMirror-activeline-bgColor: #636e7b66;
    --codeMirror-matchingBracket-fgColor: #c5d1de;
    --codeMirror-lines-bgColor: #22272e;
    --codeMirror-syntax-fgColor-comment: #768390;
    --codeMirror-syntax-fgColor-constant: #6cb6ff;
    --codeMirror-syntax-fgColor-entity: #dcbdfb;
    --codeMirror-syntax-fgColor-keyword: #f47067;
    --codeMirror-syntax-fgColor-storage: #f47067;
    --codeMirror-syntax-fgColor-string: #96d0ff;
    --codeMirror-syntax-fgColor-support: #6cb6ff;
    --codeMirror-syntax-fgColor-variable: #f69d50;
    --header-fgColor-default: #cdd9e5b3;
    --header-fgColor-logo: #cdd9e5;
    --header-bgColor: #2d333bf2;
    --header-borderColor-divider: #768390;
    --headerSearch-bgColor: #22272e;
    --headerSearch-borderColor: #444c56;
    --data-blue-color: #0576ff;
    --data-blue-color-muted: #001a47;
    --data-auburn-color: #a86f6b;
    --data-auburn-color-muted: #271817;
    --data-orange-color: #984b10;
    --data-orange-color-muted: #311708;
    --data-yellow-color: #895906;
    --data-yellow-color-muted: #2e1a00;
    --data-green-color: #2f6f37;
    --data-green-color-muted: #122117;
    --data-teal-color: #106c70;
    --data-teal-color-muted: #041f25;
    --data-purple-color: #975bf1;
    --data-purple-color-muted: #211047;
    --data-pink-color: #d34591;
    --data-pink-color-muted: #2d1524;
    --data-red-color: #eb3342;
    --data-red-color-muted: #3c0614;
    --data-gray-color: #576270;
    --data-gray-color-muted: #1c1c1c;
    --display-blue-bgColor-muted: #001a47;
    --display-blue-bgColor-emphasis: #0576ff;
    --display-blue-fgColor: #4da0ff;
    --display-blue-borderColor-muted: #002766;
    --display-blue-borderColor-emphasis: #0576ff;
    --display-green-bgColor-muted: #122117;
    --display-green-bgColor-emphasis: #388f3f;
    --display-green-fgColor: #41b445;
    --display-green-borderColor-muted: #182f1f;
    --display-green-borderColor-emphasis: #388f3f;
    --display-orange-bgColor-muted: #311708;
    --display-orange-bgColor-emphasis: #c46212;
    --display-orange-fgColor: #ed8326;
    --display-orange-borderColor-muted: #43200a;
    --display-orange-borderColor-emphasis: #c46212;
    --display-purple-bgColor-muted: #211047;
    --display-purple-bgColor-emphasis: #975bf1;
    --display-purple-fgColor: #b687f7;
    --display-purple-borderColor-muted: #31146b;
    --display-purple-borderColor-emphasis: #975bf1;
    --display-plum-bgColor-muted: #2a0e3f;
    --display-plum-bgColor-emphasis: #b643ef;
    --display-plum-fgColor: #d07ef7;
    --display-plum-borderColor-muted: #40125e;
    --display-plum-borderColor-emphasis: #b643ef;
    --display-red-bgColor-muted: #3c0614;
    --display-red-bgColor-emphasis: #eb3342;
    --display-red-fgColor: #f27d83;
    --display-red-borderColor-muted: #58091a;
    --display-red-borderColor-emphasis: #eb3342;
    --display-coral-bgColor-muted: #3c0614;
    --display-coral-bgColor-emphasis: #eb3342;
    --display-coral-fgColor: #f27d83;
    --display-coral-borderColor-muted: #58091a;
    --display-coral-borderColor-emphasis: #eb3342;
    --display-yellow-bgColor-muted: #2e1a00;
    --display-yellow-bgColor-emphasis: #aa7109;
    --display-yellow-fgColor: #d3910d;
    --display-yellow-borderColor-muted: #3d2401;
    --display-yellow-borderColor-emphasis: #aa7109;
    --display-gray-bgColor-muted: #1c1c1c;
    --display-gray-bgColor-emphasis: #6e7f96;
    --display-gray-fgColor: #92a1b5;
    --display-gray-borderColor-muted: #2a2b2d;
    --display-gray-borderColor-emphasis: #6e7f96;
    --display-auburn-bgColor-muted: #271817;
    --display-auburn-bgColor-emphasis: #a86f6b;
    --display-auburn-fgColor: #bf9592;
    --display-auburn-borderColor-muted: #3a2422;
    --display-auburn-borderColor-emphasis: #a86f6b;
    --display-brown-bgColor-muted: #241c14;
    --display-brown-bgColor-emphasis: #94774c;
    --display-brown-fgColor: #b69a6d;
    --display-brown-borderColor-muted: #342a1d;
    --display-brown-borderColor-emphasis: #94774c;
    --display-lemon-bgColor-muted: #291d00;
    --display-lemon-bgColor-emphasis: #977b0c;
    --display-lemon-fgColor: #ba9b12;
    --display-lemon-borderColor-muted: #372901;
    --display-lemon-borderColor-emphasis: #977b0c;
    --display-olive-bgColor-muted: #171e0b;
    --display-olive-bgColor-emphasis: #7a8321;
    --display-olive-fgColor: #a2a626;
    --display-olive-borderColor-muted: #252d10;
    --display-olive-borderColor-emphasis: #7a8321;
    --display-lime-bgColor-muted: #141f0f;
    --display-lime-bgColor-emphasis: #5f892f;
    --display-lime-fgColor: #7dae37;
    --display-lime-borderColor-muted: #1f3116;
    --display-lime-borderColor-emphasis: #5f892f;
    --display-pine-bgColor-muted: #082119;
    --display-pine-bgColor-emphasis: #18915e;
    --display-pine-fgColor: #1bb673;
    --display-pine-borderColor-muted: #0b3224;
    --display-pine-borderColor-emphasis: #18915e;
    --display-teal-bgColor-muted: #041f25;
    --display-teal-bgColor-emphasis: #158a8a;
    --display-teal-fgColor: #1cb0ab;
    --display-teal-borderColor-muted: #073036;
    --display-teal-borderColor-emphasis: #158a8a;
    --display-cyan-bgColor-muted: #001f29;
    --display-cyan-bgColor-emphasis: #0587b3;
    --display-cyan-fgColor: #07ace4;
    --display-cyan-borderColor-muted: #002e3d;
    --display-cyan-borderColor-emphasis: #0587b3;
    --display-indigo-bgColor-muted: #1b183f;
    --display-indigo-bgColor-emphasis: #7070e1;
    --display-indigo-fgColor: #9899ec;
    --display-indigo-borderColor-muted: #25215f;
    --display-indigo-borderColor-emphasis: #7070e1;
    --display-pink-bgColor-muted: #2d1524;
    --display-pink-bgColor-emphasis: #d34591;
    --display-pink-fgColor: #e57bb2;
    --display-pink-borderColor-muted: #451c35;
    --display-pink-borderColor-emphasis: #d34591;
    --avatar-bgColor: #cdd9e51a;
    --avatar-borderColor: #cdd9e526;
    --avatar-shadow: 0px 0px 0px 2px #22272e;
    --avatarStack-fade-bgColor-default: #444c56;
    --avatarStack-fade-bgColor-muted: #373e47;
    --control-bgColor-rest: #373e47;
    --control-bgColor-hover: #3d444e;
    --control-bgColor-active: #434a54;
    --control-bgColor-disabled: #373e47b3;
    --control-bgColor-selected: #2d333b;
    --control-fgColor-rest: #adbac7;
    --control-fgColor-placeholder: #727f8c;
    --control-fgColor-disabled: #636e7b;
    --control-borderColor-rest: #444c56;
    --control-borderColor-emphasis: #606b77;
    --control-borderColor-disabled: #373e47b3;
    --control-borderColor-selected: #cdd9e5;
    --control-borderColor-success: #347d39;
    --control-borderColor-danger: #c93c37;
    --control-borderColor-warning: #966600;
    --control-iconColor-rest: #778491;
    --control-transparent-bgColor-rest: #00000000;
    --control-transparent-bgColor-hover: #909dab1f;
    --control-transparent-bgColor-active: #909dab33;
    --control-transparent-bgColor-disabled: #373e47b3;
    --control-transparent-bgColor-selected: #909dab14;
    --control-transparent-borderColor-rest: #00000000;
    --control-transparent-borderColor-hover: #00000000;
    --control-transparent-borderColor-active: #00000000;
    --control-danger-fgColor-rest: #e5534b;
    --control-danger-fgColor-hover: #f47067;
    --control-danger-bgColor-hover: #e5534b1a;
    --control-danger-bgColor-active: #e5534b66;
    --control-checked-bgColor-rest: #316dca;
    --control-checked-bgColor-hover: #3876d3;
    --control-checked-bgColor-active: #3f7fdb;
    --control-checked-bgColor-disabled: #636e7b;
    --control-checked-fgColor-rest: #cdd9e5;
    --control-checked-fgColor-disabled: #1c2128;
    --control-checked-borderColor-rest: #316dca;
    --control-checked-borderColor-hover: #3876d3;
    --control-checked-borderColor-active: #3f7fdb;
    --control-checked-borderColor-disabled: #636e7b;
    --controlTrack-bgColor-rest: #373e47;
    --controlTrack-bgColor-hover: #3d444e;
    --controlTrack-bgColor-active: #434a54;
    --controlTrack-bgColor-disabled: #636e7b;
    --controlTrack-fgColor-rest: #778491;
    --controlTrack-fgColor-disabled: #cdd9e5;
    --controlTrack-borderColor-rest: #00000000;
    --controlTrack-borderColor-disabled: #636e7b;
    --controlKnob-bgColor-rest: #1c2128;
    --controlKnob-bgColor-disabled: #373e47b3;
    --controlKnob-bgColor-checked: #cdd9e5;
    --controlKnob-borderColor-rest: #606b77;
    --controlKnob-borderColor-disabled: #373e47b3;
    --controlKnob-borderColor-checked: #316dca;
    --counter-borderColor: #00000000;
    --button-default-fgColor-rest: #adbac7;
    --button-default-bgColor-rest: #373e47;
    --button-default-bgColor-hover: #3d444e;
    --button-default-bgColor-active: #434a54;
    --button-default-bgColor-selected: #434a54;
    --button-default-bgColor-disabled: #373e47b3;
    --button-default-borderColor-rest: #444c56;
    --button-default-borderColor-hover: #444c56;
    --button-default-borderColor-active: #444c56;
    --button-default-borderColor-disabled: #373e47b3;
    --button-default-shadow-resting: 0px 0px 0px 0px #000000;
    --button-primary-fgColor-rest: #ffffff;
    --button-primary-fgColor-disabled: #cdd9e566;
    --button-primary-iconColor-rest: #ffffff;
    --button-primary-bgColor-rest: #347d39;
    --button-primary-bgColor-hover: #3b8640;
    --button-primary-bgColor-active: #428f46;
    --button-primary-bgColor-disabled: #50a254;
    --button-primary-borderColor-rest: #cdd9e51a;
    --button-primary-borderColor-hover: #cdd9e51a;
    --button-primary-borderColor-active: #cdd9e51a;
    --button-primary-borderColor-disabled: #50a254;
    --button-primary-shadow-selected: 0px 0px 0px 0px #000000;
    --button-invisible-fgColor-rest: #478be6;
    --button-invisible-fgColor-hover: #539bf5;
    --button-invisible-fgColor-disabled: #636e7b;
    --button-invisible-iconColor-rest: #778491;
    --button-invisible-iconColor-hover: #778491;
    --button-invisible-iconColor-disabled: #636e7b;
    --button-invisible-bgColor-rest: #00000000;
    --button-invisible-bgColor-hover: #909dab1f;
    --button-invisible-bgColor-active: #909dab33;
    --button-invisible-bgColor-disabled: #373e47b3;
    --button-invisible-borderColor-rest: #00000000;
    --button-invisible-borderColor-hover: #00000000;
    --button-invisible-borderColor-disabled: #373e47b3;
    --button-outline-fgColor-rest: #4184e4;
    --button-outline-fgColor-hover: #539bf5;
    --button-outline-fgColor-active: #cdd9e5;
    --button-outline-fgColor-disabled: #478be680;
    --button-outline-bgColor-rest: #cdd9e5;
    --button-outline-bgColor-hover: #444c56;
    --button-outline-bgColor-active: #1b4b91;
    --button-outline-bgColor-disabled: #22272e;
    --button-outline-borderColor-hover: #cdd9e51a;
    --button-outline-borderColor-selected: #cdd9e51a;
    --button-outline-shadow-selected: 0px 0px 0px 0px #000000;
    --button-danger-fgColor-rest: #e5534b;
    --button-danger-fgColor-hover: #cdd9e5;
    --button-danger-fgColor-active: #cdd9e5;
    --button-danger-fgColor-disabled: #e5534b80;
    --button-danger-iconColor-rest: #e5534b;
    --button-danger-iconColor-hover: #cdd9e5;
    --button-danger-bgColor-rest: #373e47;
    --button-danger-bgColor-hover: #ad2e2c;
    --button-danger-bgColor-active: #c33d38;
    --button-danger-bgColor-disabled: #373e47b3;
    --button-danger-borderColor-rest: #444c56;
    --button-danger-borderColor-hover: #cdd9e51a;
    --button-danger-borderColor-active: #cdd9e51a;
    --button-danger-shadow-selected: 0px 0px 0px 0px #000000;
    --button-inactive-fgColor: #768390;
    --button-inactive-bgColor: #373e47;
    --button-star-iconColor: #daaa3f;
    --buttonCounter-default-bgColor-rest: #444c56;
    --buttonCounter-invisible-bgColor-rest: #444c56;
    --buttonCounter-primary-bgColor-rest: #11341733;
    --buttonCounter-outline-bgColor-rest: #0f2d5c33;
    --buttonCounter-outline-bgColor-hover: #0f2d5c33;
    --buttonCounter-outline-bgColor-disabled: #316dca0d;
    --buttonCounter-outline-fgColor-rest: #4184e4;
    --buttonCounter-outline-fgColor-hover: #539bf5;
    --buttonCounter-outline-fgColor-disabled: #478be680;
    --buttonCounter-danger-bgColor-hover: #cdd9e533;
    --buttonCounter-danger-bgColor-disabled: #c93c370d;
    --buttonCounter-danger-bgColor-rest: #5d0f1233;
    --buttonCounter-danger-fgColor-rest: #e5534b;
    --buttonCounter-danger-fgColor-hover: #cdd9e5;
    --buttonCounter-danger-fgColor-disabled: #e5534b80;
    --reactionButton-selected-bgColor-rest: #4184e433;
    --reactionButton-selected-bgColor-hover: #4285e55c;
    --reactionButton-selected-fgColor-rest: #478be6;
    --reactionButton-selected-fgColor-hover: #6cb6ff;
    --focus-outlineColor: #316dca;
    --menu-bgColor-active: #2d333b;
    --overlay-bgColor: #2d333b;
    --overlay-borderColor: #444c56b3;
    --overlay-backdrop-bgColor: #2d333b66;
    --selectMenu-borderColor: #545d68;
    --selectMenu-bgColor-active: #143d79;
    --sideNav-bgColor-selected: #373e47;
    --skeletonLoader-bgColor: #2d333b;
    --timelineBadge-bgColor: #373e47;
    --treeViewItem-leadingVisual-iconColor-rest: #778491;
    --underlineNav-borderColor-active: #ec775c;
    --underlineNav-borderColor-hover: #636e7b66;
    --underlineNav-iconColor-rest: #778491;
    --selection-bgColor: #316dcab3;
    --card-bgColor: #2d333b;
    --label-green-bgColor-rest: #122117;
    --label-green-bgColor-hover: #182f1f;
    --label-green-bgColor-active: #214529;
    --label-green-fgColor-rest: #41b445;
    --label-green-fgColor-hover: #46c144;
    --label-green-fgColor-active: #75d36f;
    --label-orange-bgColor-rest: #311708;
    --label-orange-bgColor-hover: #43200a;
    --label-orange-bgColor-active: #632f0d;
    --label-orange-fgColor-rest: #ed8326;
    --label-orange-fgColor-hover: #f1933b;
    --label-orange-fgColor-active: #f6b06a;
    --label-purple-bgColor-rest: #211047;
    --label-purple-bgColor-hover: #31146b;
    --label-purple-bgColor-active: #481a9e;
    --label-purple-fgColor-rest: #b687f7;
    --label-purple-fgColor-hover: #c398fb;
    --label-purple-fgColor-active: #d2affd;
    --label-red-bgColor-rest: #3c0614;
    --label-red-bgColor-hover: #58091a;
    --label-red-bgColor-active: #790c20;
    --label-red-fgColor-rest: #f27d83;
    --label-red-fgColor-hover: #f48b8d;
    --label-red-fgColor-active: #f7adab;
    --label-yellow-bgColor-rest: #2e1a00;
    --label-yellow-bgColor-hover: #3d2401;
    --label-yellow-bgColor-active: #5a3702;
    --label-yellow-fgColor-rest: #d3910d;
    --label-yellow-fgColor-hover: #df9e11;
    --label-yellow-fgColor-active: #edb431;
    --label-gray-bgColor-rest: #1c1c1c;
    --label-gray-bgColor-hover: #2a2b2d;
    --label-gray-bgColor-active: #393d41;
    --label-gray-fgColor-rest: #92a1b5;
    --label-gray-fgColor-hover: #9babbf;
    --label-gray-fgColor-active: #b3c0d1;
    --label-auburn-bgColor-rest: #271817;
    --label-auburn-bgColor-hover: #3a2422;
    --label-auburn-bgColor-active: #543331;
    --label-auburn-fgColor-rest: #bf9592;
    --label-auburn-fgColor-hover: #c6a19f;
    --label-auburn-fgColor-active: #d4b7b5;
    --label-brown-bgColor-rest: #241c14;
    --label-brown-bgColor-hover: #342a1d;
    --label-brown-bgColor-active: #483a28;
    --label-brown-fgColor-rest: #b69a6d;
    --label-brown-fgColor-hover: #bfa77d;
    --label-brown-fgColor-active: #cdbb98;
    --label-lemon-bgColor-rest: #291d00;
    --label-lemon-bgColor-hover: #372901;
    --label-lemon-bgColor-active: #4f3c02;
    --label-lemon-fgColor-rest: #ba9b12;
    --label-lemon-fgColor-hover: #c4a717;
    --label-lemon-fgColor-active: #d7bc1d;
    --label-olive-bgColor-rest: #171e0b;
    --label-olive-bgColor-hover: #252d10;
    --label-olive-bgColor-active: #374115;
    --label-olive-fgColor-rest: #a2a626;
    --label-olive-fgColor-hover: #b2af24;
    --label-olive-fgColor-active: #cbc025;
    --label-lime-bgColor-rest: #141f0f;
    --label-lime-bgColor-hover: #1f3116;
    --label-lime-bgColor-active: #2c441d;
    --label-lime-fgColor-rest: #7dae37;
    --label-lime-fgColor-hover: #89ba36;
    --label-lime-fgColor-active: #9fcc3e;
    --label-pine-bgColor-rest: #082119;
    --label-pine-bgColor-hover: #0b3224;
    --label-pine-bgColor-active: #0e4430;
    --label-pine-fgColor-rest: #1bb673;
    --label-pine-fgColor-hover: #1ac176;
    --label-pine-fgColor-active: #1bda81;
    --label-teal-bgColor-rest: #041f25;
    --label-teal-bgColor-hover: #073036;
    --label-teal-bgColor-active: #0a464d;
    --label-teal-fgColor-rest: #1cb0ab;
    --label-teal-fgColor-hover: #1fbdb2;
    --label-teal-fgColor-active: #24d6c4;
    --label-cyan-bgColor-rest: #001f29;
    --label-cyan-bgColor-hover: #002e3d;
    --label-cyan-bgColor-active: #014156;
    --label-cyan-fgColor-rest: #07ace4;
    --label-cyan-fgColor-hover: #09b7f1;
    --label-cyan-fgColor-active: #45cbf7;
    --label-indigo-bgColor-rest: #1b183f;
    --label-indigo-bgColor-hover: #25215f;
    --label-indigo-bgColor-active: #312c90;
    --label-indigo-fgColor-rest: #9899ec;
    --label-indigo-fgColor-hover: #a2a5f1;
    --label-indigo-fgColor-active: #b7baf6;
    --label-blue-bgColor-rest: #001a47;
    --label-blue-bgColor-hover: #002766;
    --label-blue-bgColor-active: #00378a;
    --label-blue-fgColor-rest: #4da0ff;
    --label-blue-fgColor-hover: #61adff;
    --label-blue-fgColor-active: #85c2ff;
    --label-plum-bgColor-rest: #2a0e3f;
    --label-plum-bgColor-hover: #40125e;
    --label-plum-bgColor-active: #5c1688;
    --label-plum-fgColor-rest: #d07ef7;
    --label-plum-fgColor-hover: #d889fa;
    --label-plum-fgColor-active: #e4a5fd;
    --label-pink-bgColor-rest: #2d1524;
    --label-pink-bgColor-hover: #451c35;
    --label-pink-bgColor-active: #65244a;
    --label-pink-fgColor-rest: #e57bb2;
    --label-pink-fgColor-hover: #ec8dbd;
    --label-pink-fgColor-active: #f4a9cd;
    --label-coral-bgColor-rest: #351008;
    --label-coral-bgColor-hover: #51180b;
    --label-coral-bgColor-active: #72220d;
    --label-coral-fgColor-rest: #f7794b;
    --label-coral-fgColor-hover: #fa8c61;
    --label-coral-fgColor-active: #fdaa86;
    --fgColor-default: #c5d1de;
    --fgColor-muted: #778491;
    --fgColor-onEmphasis: #cdd9e5;
    --fgColor-white: #cdd9e5;
    --fgColor-black: #1c2128;
    --fgColor-disabled: #636e7b;
    --fgColor-link: #478be6;
    --fgColor-neutral: #636e7b;
    --fgColor-accent: #478be6;
    --fgColor-success: #57ab5a;
    --fgColor-attention: #c69026;
    --fgColor-severe: #cc6b2c;
    --fgColor-danger: #e5534b;
    --fgColor-open: #57ab5a;
    --fgColor-closed: #e5534b;
    --fgColor-done: #986ee2;
    --fgColor-upsell: #986ee2;
    --fgColor-sponsors: #c96198;
    --bgColor-default: #22272e;
    --bgColor-muted: #2d333b;
    --bgColor-inset: #1c2128;
    --bgColor-emphasis: #636e7b;
    --bgColor-inverse: #cdd9e5;
    --bgColor-white: #cdd9e5;
    --bgColor-black: #1c2128;
    --bgColor-disabled: #373e47b3;
    --bgColor-transparent: #00000000;
    --bgColor-neutral-muted: #636e7b66;
    --bgColor-neutral-emphasis: #636e7b;
    --bgColor-accent-muted: #4184e41a;
    --bgColor-accent-emphasis: #316dca;
    --bgColor-success-muted: #46954a26;
    --bgColor-success-emphasis: #347d39;
    --bgColor-attention-muted: #ae7c1426;
    --bgColor-attention-emphasis: #966600;
    --bgColor-severe-muted: #cc6b2c1a;
    --bgColor-severe-emphasis: #ae5622;
    --bgColor-danger-muted: #e5534b1a;
    --bgColor-danger-emphasis: #c93c37;
    --bgColor-open-muted: #46954a1a;
    --bgColor-open-emphasis: #347d39;
    --bgColor-closed-muted: #e5534b26;
    --bgColor-closed-emphasis: #c93c37;
    --bgColor-done-muted: #986ee226;
    --bgColor-done-emphasis: #8256d0;
    --bgColor-upsell-muted: #986ee226;
    --bgColor-upsell-emphasis: #8256d0;
    --bgColor-sponsors-muted: #c961981a;
    --bgColor-sponsors-emphasis: #ae4c82;
    --borderColor-default: #444c56;
    --borderColor-muted: #444c56b3;
    --borderColor-emphasis: #545d68;
    --borderColor-disabled: #373e47b3;
    --borderColor-transparent: #00000000;
    --borderColor-translucent: #cdd9e526;
    --borderColor-neutral-muted: #636e7b66;
    --borderColor-neutral-emphasis: #636e7b;
    --borderColor-accent-muted: #4184e466;
    --borderColor-accent-emphasis: #316dca;
    --borderColor-success-muted: #46954a66;
    --borderColor-success-emphasis: #347d39;
    --borderColor-attention-muted: #ae7c1466;
    --borderColor-attention-emphasis: #966600;
    --borderColor-severe-muted: #cc6b2c66;
    --borderColor-severe-emphasis: #ae5622;
    --borderColor-danger-muted: #e5534b66;
    --borderColor-danger-emphasis: #c93c37;
    --borderColor-open-muted: #46954a66;
    --borderColor-open-emphasis: #347d39;
    --borderColor-closed-muted: #e5534b66;
    --borderColor-closed-emphasis: #c93c37;
    --borderColor-done-muted: #986ee266;
    --borderColor-done-emphasis: #8256d0;
    --borderColor-upsell-muted: #986ee266;
    --borderColor-upsell-emphasis: #8256d0;
    --borderColor-sponsors-muted: #c9619866;
    --borderColor-sponsors-emphasis: #ae4c82;
    --color-ansi-black: #545d68;
    --color-ansi-black-bright: #636e7b;
    --color-ansi-white: #909dab;
    --color-ansi-white-bright: #cdd9e5;
    --color-ansi-gray: #636e7b;
    --color-ansi-red: #f47067;
    --color-ansi-red-bright: #ff938a;
    --color-ansi-green: #57ab5a;
    --color-ansi-green-bright: #6bc46d;
    --color-ansi-yellow: #c69026;
    --color-ansi-yellow-bright: #daaa3f;
    --color-ansi-blue: #539bf5;
    --color-ansi-blue-bright: #6cb6ff;
    --color-ansi-magenta: #b083f0;
    --color-ansi-magenta-bright: #dcbdfb;
    --color-ansi-cyan: #39c5cf;
    --color-ansi-cyan-bright: #56d4dd;
    --color-prettylights-syntax-comment: #768390;
    --color-prettylights-syntax-constant: #6cb6ff;
    --color-prettylights-syntax-constant-other-reference-link: #96d0ff;
    --color-prettylights-syntax-entity: #dcbdfb;
    --color-prettylights-syntax-storage-modifier-import: #adbac7;
    --color-prettylights-syntax-entity-tag: #8ddb8c;
    --color-prettylights-syntax-keyword: #f47067;
    --color-prettylights-syntax-string: #96d0ff;
    --color-prettylights-syntax-variable: #f69d50;
    --color-prettylights-syntax-brackethighlighter-unmatched: #e5534b;
    --color-prettylights-syntax-brackethighlighter-angle: #768390;
    --color-prettylights-syntax-invalid-illegal-text: #cdd9e5;
    --color-prettylights-syntax-invalid-illegal-bg: #922323;
    --color-prettylights-syntax-carriage-return-text: #cdd9e5;
    --color-prettylights-syntax-carriage-return-bg: #ad2e2c;
    --color-prettylights-syntax-string-regexp: #8ddb8c;
    --color-prettylights-syntax-markup-list: #eac55f;
    --color-prettylights-syntax-markup-heading: #316dca;
    --color-prettylights-syntax-markup-italic: #adbac7;
    --color-prettylights-syntax-markup-bold: #adbac7;
    --color-prettylights-syntax-markup-deleted-text: #ffd8d3;
    --color-prettylights-syntax-markup-deleted-bg: #78191b;
    --color-prettylights-syntax-markup-inserted-text: #b4f1b4;
    --color-prettylights-syntax-markup-inserted-bg: #1b4721;
    --color-prettylights-syntax-markup-changed-text: #ffddb0;
    --color-prettylights-syntax-markup-changed-bg: #682d0f;
    --color-prettylights-syntax-markup-ignored-text: #adbac7;
    --color-prettylights-syntax-markup-ignored-bg: #255ab2;
    --color-prettylights-syntax-meta-diff-range: #dcbdfb;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #545d68;
    --shadow-inset: inset 0px 1px 0px 0px #1c21283d;
    --shadow-resting-xsmall: 0px 1px 0px 0px #1c2128cc;
    --shadow-resting-small: 0px 1px 0px 0px #1c212866;
    --shadow-resting-medium: 0px 3px 6px 0px #1c2128cc;
    --shadow-floating-small: 0px 0px 0px 1px #444c56, 0px 6px 12px -3px #1c212866, 0px 6px 18px 0px #1c212866;
    --shadow-floating-medium: 0px 0px 0px 1px #444c56, 0px 8px 16px -4px #1c212866, 0px 4px 32px -4px #1c212866, 0px 24px 48px -12px #1c212866, 0px 48px 96px -24px #1c212866;
    --shadow-floating-large: 0px 0px 0px 1px #444c56, 0px 24px 48px 0px #1c2128;
    --shadow-floating-xlarge: 0px 0px 0px 1px #444c56, 0px 32px 64px 0px #1c2128;
    --shadow-floating-legacy: 0px 6px 12px -3px #1c212866, 0px 6px 18px 0px #1c212866;
    --outline-focus: #316dca solid 2px;
  }
}
